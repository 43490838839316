import React, { useEffect } from 'react';
import {
	IonTabs,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonIcon,
	IonLabel,
} from '@ionic/react';
import { fileTrayOutline, fileTrayFullOutline } from 'ionicons/icons';
import ProtectedRoute from './route/ProtectedRoute';
import OrdersAct from './dashboard/Orders/OrdersAct';
import AllOrders from './dashboard/Orders/AllOrders';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from './services/auth/userLocalStorage';
import { getOrders } from './actions/orderActions';
import OrderDetails from './dashboard/Orders/OrderDetails';
// import OrdersCOPY from './dashboard/Orders/OrdersCopy';

interface OrderTabsProps {}

const OrderTabs: React.FC<OrderTabsProps> = () => {
	const dispatch = useDispatch();

	const { updateOrderMessage } = useSelector(
		(state: any) => state.updateOrders
	);

	useEffect(() => {
		if (updateOrderMessage?.message || !updateOrderMessage) {
			dispatch(getOrders(getAccessToken()));
		}
	}, [updateOrderMessage]);

	return (
		<IonTabs>
			<IonRouterOutlet>
				<ProtectedRoute path="/orders/act" component={OrdersAct} exact={true} />
				<ProtectedRoute path="/orders/all" component={AllOrders} exact={true} />
				<ProtectedRoute
					path="/orders/order-details/:orderId"
					component={OrderDetails}
					exact={true}
				/>
				{/* end added */}
			</IonRouterOutlet>
			<IonTabBar slot="bottom">
				<IonTabButton tab="stores" href="/orders/act">
					<IonIcon icon={fileTrayOutline} />
					<IonLabel>Act</IonLabel>
				</IonTabButton>
				<IonTabButton tab="schedule" href="/orders/all">
					<IonIcon icon={fileTrayFullOutline} />
					<IonLabel>All</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
};

export default OrderTabs;
