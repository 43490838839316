import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemOptions,
	IonLabel,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import {
	arrowBack as arrowBackIcon,
	informationCircle as informationCircleIcon,
	closeOutline,
	removeCircle as removeCircleIcon,
	caretDown as caretDownIcon,
	addCircle as addCircleIcon,
	addOutline as addOutlineIcon,
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { getStoresCategory } from '../actions/categoryAction';
import { getProductById, updateProduct } from '../actions/productActions';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../constants/global';

interface RouteParams {
	id: string;
}
// interface Product {
// 	id: string;
// 	name: string;
// 	status: string;
// 	image: string;
// 	image_display_url: string;
// 	weight_in_gms: string;
// 	laundry_service_price: string;
// 	dimension: string;
// 	help_text: string;
// 	internal_notes: string;
// }
async function savePicture(file) {
	if (!file) return '';
	const formData = new FormData();
	formData.append('file', file);
	try {
		const response = await fetch(`${API_ROOT}upload/product-image`, {
			method: 'POST',
			body: formData,
		});
		const data = await response.json();
		return data.platform_path;
	} catch (error) {
		return '';
	}
}

const placeholderlist = {
	Color: 'eg. Red, Blue, Green',
	Size: 'eg. Small, Medium, Large',
	Material: 'eg. Cotton, Wool, Polyester',
	Weight: 'eg. 500gm, 1Kg',
	'Add yours': 'Add yours',
};

// const KeyCodes = {
//   comma: 188,
//   enter: [10, 13],
// };

// const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const UpdateProduct = (props: any) => {
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const params: { id: string } = useParams();
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [isEdited, setIsEdited] = useState(false);

	const dispatch = useDispatch();
	const { currentUser } = useSelector((state: any) => state.currentUser);
	// const { fetchCategoriesLoading, categories, fetchCategoriesError } =
	// 	useSelector((state: any) => state.categories);
	// const { createCategoryLoading, createCategory, createCategoryError } =
	// 	useSelector((state: any) => state.categories);

	// var categoryFromProps;
	// if (props && props.location && props.location.state) {
	// 	categoryFromProps = props.location.state;
	// }
	// console.log(createProductMessage, ' createProductMessage');
	// console.log(createProductError, ' createProductError');
	const history = useHistory();
	// const [product, setProduct] = useState<Product>();
	//states to store form data
	const [productName, setProductName] = useState<any>('');
	const [productStatus, setProductStatus] = useState('');
	const [weight, setWeight] = useState<string>();
	const [servicePrice, setServicePrice] = useState<string>();
	const [dimension, setDimension] = useState<string>('');
	const [helpText, setHelpText] = useState<string>('');
	const [internalNotes, setInternalNotes] = useState<string>('');

	//states to check empty or not
	const [isProductNameEmpty, setIsProductNameEmpty] = useState<boolean>(false);
	const [isServicePriceEmpty, setIsServicePriceEmpty] =
		useState<boolean>(false);
	const [isImageEmpty, setIsImageEmpty] = useState<boolean>(false);
	const [isWeightEmpty, setIsWeightEmpty] = useState<boolean>(false);
	const [isDimensionEmpty, setIsDimensionEmpty] = useState<boolean>(false);
	const [isHelpTextEmpty, setIsHelpTextEmpty] = useState<boolean>(false);
	const [isInternalNotesEmpty, setIsInternalNotesEmpty] =
		useState<boolean>(false);

	//states to validate
	const [isServicePriceValidate, setIsServicePriceValidate] =
		useState<boolean>(true);
	const [isImageValidate, setIsImageValidate] = useState<boolean>(true);
	const [isWeightValidate, setIsWeightValidate] = useState<boolean>(true);

	const [platformPath, setPlatformPath] = useState<any>('');

	const fileInputRef = useRef<HTMLInputElement>();
	const [pictureUrl, setPictureUrl] = useState('');

	const { productById, productByIdLoading, productByIdError } = useSelector(
		(state: any) => state.productById
	);

	const { editProductLoading, editProductMessage, editProductError } =
		useSelector((state: any) => state.updateProductData);

	// console.log(Object.keys(product));
	// console.log(productByIdLoading);

	useEffect(() => {
		setLoading(false);

		if (
			editProductMessage?.id &&
			editProductMessage?.id === parseInt(params.id) &&
			isEdited
		) {
			//if product added successfully
			setShowToast(true);
			setToastMessage('Product Updated Successfully');
		}
		if (editProductError) {
			//if any error happened
			setShowToast(true);
			setToastMessage('Something went wrong');
		}
	}, [editProductMessage, editProductError, params.id]);

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.files.length > 0) {
			const file = event.target.files.item(0);
			if (file.type.startsWith('image')) {
				let temp2 = true;
				setIsImageValidate(temp2);
				const myPictureUrl = URL.createObjectURL(file);
				let temp3 = myPictureUrl;
				setPictureUrl(temp3);
				setLoading(true);
				const myPlatformPath = await savePicture(file);
				setLoading(false);
				if (myPlatformPath) {
					let temp4 = myPlatformPath;
					setPlatformPath(temp4);
				}
			} else {
				let temp2 = false;
				setIsImageValidate(temp2);
			}
		}
	};

	const handlePictureClick = async () => {
		if (isPlatform('capacitor')) {
			try {
				const photo = await Camera.getPhoto({
					resultType: CameraResultType.Uri,
				});
				let temp = photo.webPath;
				setPictureUrl(temp);
			} catch (error) {
				console.log(error);
			}
		} else {
			fileInputRef.current.click();
		}
	};

	//validate functions
	const productNameValidation = () => {
		let result = true;
		if (productName.length < 3) {
			setIsProductNameEmpty(true);
			result = false;
		} else {
			setIsProductNameEmpty(false);
		}
		return result;
	};

	const imageValidation = () => {
		let result = true;
		if (!pictureUrl) {
			setIsImageEmpty(true);
			console.log(pictureUrl);
			result = false;
		} else {
			setIsImageEmpty(false);
		}
		return result;
	};
	const priceValidation = () => {
		let result = true;
		if (!servicePrice) {
			setIsServicePriceEmpty(true);
			result = false;
		} else {
			setIsServicePriceEmpty(false);
			if (!/^\d+(\.\d{1,2})?$/.test(servicePrice)) {
				setIsServicePriceValidate(false);
				result = false;
			} else {
				setIsServicePriceValidate(true);
			}
		}
		return result;
	};

	const weightValidation = () => {
		// Initialize result variable
		let result = true;
		// Check if weight is empty
		if (!weight) {
			setIsWeightEmpty(true);
			result = false;
		} else {
			setIsWeightEmpty(false);
			// Check if weight matches the required format
			if (!/^\d+(\.\d{1,2})?$/.test(weight)) {
				setIsWeightValidate(false);
				result = false;
			} else {
				setIsWeightValidate(true);
			}
		}
		return result;
	};

	const dimensionValidation = () => {
		let result = true;
		if (!dimension.length) {
			setIsDimensionEmpty(true);
			result = false;
		} else {
			setIsDimensionEmpty(false);
		}
		return result;
	};

	//Add validation for help_text
	const helpTextValidation = () => {
		let result = true;
		if (!helpText.length) {
			setIsHelpTextEmpty(true);
			result = false;
		} else {
			setIsHelpTextEmpty(false);
		}
		return result;
	};

	// Add validation for internal_notes
	const internalNotesValidation = () => {
		let result = true;
		if (!internalNotes.length) {
			setIsInternalNotesEmpty(true);
			result = false;
		} else {
			setIsInternalNotesEmpty(false);
		}
		return result;
	};

	// get products by id
	useEffect(() => {
		const accessToken = getAccessToken();

		if (accessToken && currentUser && currentUser.data && params.id) {
			dispatch(getProductById(params.id, accessToken.token));
		}
	}, [currentUser, params?.id]);
	console.log(productById);
	useEffect(() => {
		if (!productByIdLoading && productById?.id) {
			// setProduct({ ...productById });
			setProductName(productById.name);
			setServicePrice(productById.laundry_service_price);
			setWeight(productById.weight_in_gms);
			setDimension(productById.dimension);
			setHelpText(productById.help_text);
			setInternalNotes(productById.internal_notes);
			setProductStatus(productById.status);
			setPlatformPath(productById.image);
			setPictureUrl(productById.image_display_url);
		}
	}, [productById, productByIdLoading]);
	// console.log(productByIdLoading);

	// console.log('productByID ', productById);

	function removeEmojis(string) {
		var regex =
			/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
		return string.replace(regex, '');
	}

	const updateProductFunction = async () => {
		const accessToken = await getAccessToken();
		const res1 = productNameValidation();
		const res2 = imageValidation();
		const res3 = priceValidation();
		const res4 = weightValidation();
		const res5 = dimensionValidation();
		const res6 = helpTextValidation();
		const res7 = internalNotesValidation();
		if (res1 && res2 && res3 && res4 && res5 && res6 && res7 && accessToken) {
			console.log('everything is fine');
			console.log(params.id);
			console.log(productById?.id);
			console.log(platformPath);
			const product = {
				id: productById?.id,
				name: productName,
				image: platformPath,
				status: productStatus,
				weight_in_gms: weight,
				laundry_service_price: servicePrice,
				dimension: dimension,
				help_text: helpText,
				internal_notes: internalNotes,
			};
			console.log(product);
			setIsEdited(true);
			// {
			// 	"id": 0,
			// 	"name": "freecodecamp",
			// 	"image": "/products/ffelu1mif4_ionicLogo.png.png",
			// 	"status": "active",
			// 	"weight_in_gms": "26.02",
			// 	"laundry_service_price": "23",
			// 	"dimension": "asdfjkldk jkj jsjfkljo",
			// 	"help_text": "jkasfkj jkl jkl",
			// 	"internal_notes": "kljj jadfoi ifjsdkl jkl"
			// }
			dispatch(updateProduct(product, accessToken));
		}

		// 	const res1 = productNameValidation();
		// 	const res2 = SellingPriceValidation();
		// 	const res3 = MRPValidation();
		// 	const res4 = quantityValidation();
		// 	const res5 = unitNameValidation();
		// 	//const res6 = descriptionValidation();
		// 	const res7 = imageValidation();
		// 	const res8 = variantsValidation();
		// 	const res10 = minQtyValidation();
		// 	const res11 = maxQtyValidation();
		// 	const res12 = duplicateValidation(undefined);
		// 	if (
		// 		res1 &&
		// 		res2 &&
		// 		res3 &&
		// 		res4 &&
		// 		res5 &&
		// 		res7 &&
		// 		res10 &&
		// 		res11 &&
		// 		accessToken &&
		// 		currentUser.data[0].store_id &&
		// 		product
		// 	) {
		// 		if (isVariantChecked === true) {
		// 			if (res8 && res12) {
		// 				await dispatch(
		// 					editProduct(
		// 						{
		// 							id: product.id,
		// 							name: `${productName}`,
		// 							desc_html: `${removeEmojis(productDes)}`,
		// 							//"status": `${productStatus}`,
		// 							category_id: category.id,
		// 							store_id: currentUser.data[0].store_id,
		// 							sku: sku,
		// 							variants: [
		// 								...optionList.map((val, index) => {
		// 									return {
		// 										id: product.variants[index]
		// 											? product.variants[index].id
		// 											: null,
		// 										product_id: product.id,
		// 										mrp: `${sellingPriceList[`${val}`]}`,
		// 										quantity: `${quantity}`,
		// 										min_order_quantity: `${minQty}`,
		// 										max_order_quantity: `${maxQty}`,
		// 										display_mrp: `${mrpList[`${val}`]}`,
		// 										quantity_unit_name: `${unitName}`,
		// 										// "available_quantity": `${stockList[val]}`,
		// 										images: [
		// 											...platformPaths.map(value => {
		// 												return {
		// 													image_url_original: value,
		// 												};
		// 											}),
		// 										],
		// 										attribute_value_mappings: [
		// 											...val.split(' / ').map((value, ind) => {
		// 												return {
		// 													attribute_name: optionName[ind],
		// 													attribute_value: value,
		// 												};
		// 											}),
		// 										],
		// 									};
		// 								}),
		// 							],
		// 						},
		// 						accessToken
		// 					)
		// 				);
		// 				setLoading(false);
		// 				if (loading === false && editProductMessage) {
		// 					history.goBack();
		// 				}
		// 			}
		// 		} else {
		// 			await dispatch(
		// 				editProduct(
		// 					{
		// 						id: product.id,
		// 						name: `${productName}`,
		// 						desc_html: `${removeEmojis(productDes)}`,
		// 						//"status": `${productStatus}`,
		// 						category_id: category.id,
		// 						store_id: currentUser.data[0].store_id,
		// 						sku: sku,
		// 						variants: [
		// 							{
		// 								id: product.variants[0].id,
		// 								product_id: product.id,
		// 								mrp: `${sellingPrice}`,
		// 								quantity: `${quantity}`,
		// 								min_order_quantity: `${minQty}`,
		// 								max_order_quantity: `${maxQty}`,
		// 								display_mrp: `${MRP}`,
		// 								quantity_unit_name: `${unitName}`,
		// 								images: [
		// 									...platformPaths.map(value => {
		// 										return {
		// 											image_url_original: value,
		// 										};
		// 									}),
		// 								],
		// 								attribute_value_mappings: null,
		// 							},
		// 						],
		// 					},
		// 					accessToken
		// 				)
		// 			);

		// 			setLoading(false);
		// 			if (loading === false && editProductMessage) {
		// 				history.goBack();
		// 			}
		// 		}
		// 	}
		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
						/>
					</IonButtons>
					<IonTitle>Update Product</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<div
					style={{
						maxWidth: '80%',
						margin: '0 auto',
						border: '1px solid #eee',
					}}
					className="ion-padding"
				>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonCard
									style={{
										cursor: 'pointer',
										width: 150 + 'px',
										height: 120 + 'px',
										margin: '0 auto',
									}}
								>
									<input
										hidden
										type="file"
										accept="image/*"
										ref={fileInputRef}
										onChange={handleFileChange}
									/>
									<img
										alt=""
										width="100%"
										height="100%"
										src={pictureUrl}
										onClick={e => {
											handlePictureClick();
										}}
									></img>
								</IonCard>
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonText
						color="danger"
						className={`${isImageValidate === true ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Only image files are allowed.
						</h5>
					</IonText>
					<IonText
						color="danger"
						className={`${isImageEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Minimum one image is required.
						</h5>
					</IonText>

					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Product Name</IonLabel>
						{/* <input
							type="text"
							defaultValue={productName}
							onChange={e => setProductName(e.target.value)}
							name=""
							id=""
						/> */}
						<IonInput
							placeholder="Enter product name"
							type="text"
							value={productName}
							onIonChange={e => {
								setProductName(e.detail.value);
								// setIsProductNameEmpty(false);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isProductNameEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Product name must have minimum 3 characters.
						</h5>
					</IonText>
					<br />
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Selling Price</IonLabel>
						<IonInput
							placeholder="Enter selling price"
							type="text"
							value={servicePrice}
							onIonChange={e => {
								setServicePrice(e.detail.value);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${
							!isServicePriceEmpty && isServicePriceValidate ? 'ion-hide' : ''
						}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isServicePriceEmpty ? '' : 'ion-hide'
							}`}
						>
							Service price is required.
						</h5>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isServicePriceValidate ? 'ion-hide' : ''
							}`}
						>
							Service price must contain only numbers and 2 digits after
							decimal.
						</h5>
					</IonText>
					<br />
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Weight (in gms)</IonLabel>
						<IonInput
							placeholder="Enter Weight in grams"
							type="text"
							// defaultValue={product.weight_in_gms}
							value={weight}
							onIonChange={e => {
								setWeight(e.detail.value);
								// setIsWeightEmpty(false);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${
							!isWeightEmpty && isWeightValidate ? 'ion-hide' : ''
						}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isWeightEmpty ? '' : 'ion-hide'
							}`}
						>
							Service price is required.
						</h5>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isWeightValidate ? 'ion-hide' : ''
							}`}
						>
							Weight must contain only numbers and 2 digits after decimal.
						</h5>
					</IonText>
					<br />
					{/* Dimension */}
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Dimension</IonLabel>
						<IonInput
							placeholder="Enter Dimension"
							type="text"
							value={dimension}
							onIonChange={e => {
								setDimension(e.detail.value);
								setIsDimensionEmpty(false);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isDimensionEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Dimension can not be empty.
						</h5>
					</IonText>
					<br />
					{/* Help Text */}
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Help Text</IonLabel>
						<IonInput
							placeholder="Enter help text here"
							type="text"
							value={helpText}
							onIonChange={e => {
								setHelpText(e.detail.value);
								// setIsWeightEmpty(false);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isHelpTextEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Help Text can not be empty.
						</h5>
					</IonText>
					<br />
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Internal Notes</IonLabel>
						<IonInput
							placeholder="Enter internal notes here..."
							type="text"
							value={internalNotes}
							onIonChange={e => {
								setInternalNotes(e.detail.value);
								// setIsWeightEmpty(false);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isInternalNotesEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Internal Notes can not be empty.
						</h5>
					</IonText>
					<br />
					<IonItem>
						<IonLabel>Product Status</IonLabel>
						<IonSelect
							slot="end"
							value={productStatus}
							onIonChange={e => setProductStatus(e.detail.value)}
						>
							<IonSelectOption value="active">Active</IonSelectOption>
							<IonSelectOption value="inactive">Inactive</IonSelectOption>
						</IonSelect>
					</IonItem>

					<br />
					<div className="ion-text-center">
						<IonButton
							shape="round"
							onClick={e => {
								updateProductFunction();
							}}
						>
							Update Product
						</IonButton>
					</div>

					<IonLoading
						isOpen={
							!!productByIdLoading || !!editProductLoading
							// || fetchCategoriesLoading
						}
						message="Please Wait"
					/>
				</div>
				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={() => {
						setToastMessage('');
						setShowToast(false);
					}}
					duration={2000}
					position="top"
				></IonToast>
			</IonContent>
		</IonPage>
	);
};

export default UpdateProduct;
