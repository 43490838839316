import React, { useEffect, useState } from 'react';
import {
	IonPage,
	IonContent,
	IonList,
	IonLoading,
	IonCard,
	IonCardContent,
	IonText,
	IonButton,
	IonGrid,
} from '@ionic/react';
import Title from '../../components/dashboard/Header';
import { useSelector } from 'react-redux';
import ShortInfo from '../../components/order/ShortInfo';
import OrderList from '../../components/order/OrderList';
import { useHistory } from 'react-router';

const OrdersAct: React.FC = () => {
	const { ordersLoading, orders, ordersError } = useSelector(
		(state: any) => state.orders
	);
	const history = useHistory();
	const [filteredOrder, setFilteredOrder] = useState<any>([]);

	useEffect(() => {
		if (!ordersLoading) {
			setFilteredOrder(
				orders.filter(
					(order: any) =>
						order.status === 'APPROVED' || order.status === 'VERIFIED'
				)
			);
		}
	}, [orders]);
	// console.log(filteredOrder);
	return (
		<IonPage>
			<Title name="Orders" />
			<IonContent className="ion-margin-top">
				{!ordersLoading && filteredOrder.length > 0 ? (
					<OrderList orders={filteredOrder} />
				) : (
					<>
						<IonCard
							className="responsive-width"
							style={{
								marginTop: 16 + 'px',
							}}
						>
							<IonCardContent>
								<IonText
								// className={`${
								// 	ordersLoading ? 'ion-hide' : 'ion-margin noorderstext'
								// }`}
								>
									No Orders for your action.
								</IonText>
							</IonCardContent>
						</IonCard>
						<IonGrid className="ion-text-center ion-margin-vertical">
							<IonButton
								shape="round"
								type="submit"
								disabled={ordersLoading}
								onClick={() => {
									history.push('/orders/all');
								}}
							>
								<IonText color={'light'}>Show All Orders</IonText>
							</IonButton>
						</IonGrid>
					</>
				)}
				<IonLoading isOpen={ordersLoading} message="Please wait..." />
			</IonContent>
		</IonPage>
	);
};

export default OrdersAct;
