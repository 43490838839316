import { ErrorMessage } from '@hookform/error-message';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import {
	getOrdersByStatus,
	createAdjustments,
	editAdjustments,
	deleteAdjustments,
	changeOrderStaffFunction,
	changeOrderStatusFunction,
} from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import {
	arrowBack as arrowBackIcon,
	shareSocial as shareSocialIcon,
	callOutline as callOutlineIcon,
	navigateOutline as navigateOutlineIcon,
	documentText as documentTextIcon,
	pencil as pencilIcon,
	trash as trashIcon,
	closeOutline,
} from 'ionicons/icons';
import {
	IonLoading,
	IonIcon,
	IonCol,
	IonHeader,
	IonPage,
	IonToolbar,
	IonTitle,
	IonContent,
	IonCard,
	IonCardContent,
	IonList,
	IonItem,
	IonButton,
	IonSelect,
	IonSelectOption,
	IonButtons,
	IonBackButton,
	IonLabel,
	IonGrid,
	IonRow,
	IonText,
	IonImg,
	IonModal,
	IonInput,
	useIonViewDidEnter,
	useIonViewWillEnter,
	useIonViewDidLeave,
	IonRadioGroup,
	IonRadio,
	IonAlert,
	IonThumbnail,
	IonChip,
	IonCardHeader,
	InputChangeEventDetail,
	IonToast,
} from '@ionic/react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { formatDate } from '../../services/formatters';
import { Share } from '@capacitor/share';
import { useHistory, useLocation, useParams } from 'react-router';
import { caretDown as caretDownIcon } from 'ionicons/icons';
import Title from '../../components/dashboard/Header';
import { editOrderStatus, getOrdersById } from '../../actions/orderActions';

interface RouteParams {
	orderId: string;
}
const OrderDetails = () => {
	const [showAlert, setShowAlert] = useState(false);
	const { orderId } = useParams<RouteParams>();
	const dispatch = useDispatch();
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>('');
	const { orderLoading, order } = useSelector((state: any) => state.orderById);
	const [isUpdated, setIsUpdated] = useState<boolean>(false);

	const {
		setValue,
		control,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm({});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'order_products',
	});

	const { updateOrderMessage, updateOrderLoading } = useSelector(
		(state: any) => state.updateOrders
	);

	const [isApproved, setIsApproved] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const history = useHistory();

	const location = useLocation();
	useEffect(() => {
		if (order?.data) {
			setIsApproved(order?.data?.status === 'APPROVED');
			setIsVerified(order?.data?.status === 'VERIFIED');
			// console.log(order?.data?.count_pickup);
			// setCountVerification(Number(order?.data?.count_pickup));
			setValue(
				'order_products',
				order?.data?.order_products.map((product: any) => {
					// console.log(product?.count_verification);
					return {
						id: product.id,
						count_pickup: product.count_pickup,
						count_verification: product.count_verification,
						count_ready_for_dispatch: product.count_ready_for_dispatch,
						count_out_for_delivery: product.count_out_for_delivery,
						count_delivered: product.count_delivered,
						notes_pickup: product.notes_pickup,
					};
				})
			);
		}
	}, [order]);
	// console.log(order?.data?.status);
	useEffect(() => {
		if (updateOrderMessage?.message && isUpdated) {
			history.push('/orders/act');
			setShowToast(true);
			setToastMessage(updateOrderMessage?.message);
		}
	}, [updateOrderMessage]);

	const onSubmit = (data: any) => {
		const updatedOrder = {
			business_id: order.data.business_id,
			status:
				order?.data?.status === 'APPROVED' ? 'VERIFIED' : 'READY_FOR_DISPATCH',
			weight_at_pickup_gms: order.data.weight_at_pickup_gms,
			order_products: data.order_products,
		};
		// console.log(data.order_products);
		// console.log(updatedOrder);

		dispatch(editOrderStatus(getAccessToken(), orderId, updatedOrder));
		setIsUpdated(true);
	};

	useEffect(() => {
		dispatch(getOrdersById(getAccessToken(), orderId));
	}, [orderId, updateOrderMessage?.message, isUpdated]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
						/>
					</IonButtons>
					<IonTitle>Order #{location.state} Details</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{order?.data ? (
					<form
						className="responsive-width"
						onSubmit={e => {
							e.preventDefault();
							setShowAlert(true);
						}}
					>
						<IonCard
							className=""
							style={{
								marginTop: '16px',
							}}
						>
							<IonCardHeader>
								<IonRow className="ion-align-items-center">
									<IonCol sizeMd="2" size="2.7">
										<img
											src={order.data.business.logo_display_url}
											alt="Business Logo"
											style={{
												height: '80px',
												width: '80px',
												borderRadius: '8px',
											}}
										/>
									</IonCol>
									<IonCol sizeMd="3" size="4">
										<IonItem lines="none">
											{/* <h4 style={{}}> */}
											{order.data.business.name}
											{/* Order #{order.data.id} */}
											{/* </h4> */}
										</IonItem>
									</IonCol>
									<IonCol sizeMd="7" size="5.3">
										{order?.data && (
											<div
												style={{
													textAlign: 'end',
												}}
											>
												<IonLabel className="">
													{/* <h1 
													style={{
														color: 'black',
														paddingTop: '10px',
													}}
												>*/}
													{formatDate(
														order.data.created_at,
														'DD MMM YY, hh:mm A'
													)}
													{/* </h1> */}
												</IonLabel>
											</div>
										)}
									</IonCol>
								</IonRow>
							</IonCardHeader>

							<IonCardContent>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row-reverse',
										marginRight: '1.5vw',
									}}
								>
									{/* {order?.data && order?.data?.status === 'NEW' && ( */}
									<IonChip slot="end">
										{/* <div
											style={{
												width: 'auto',
												height: 'auto',
												display: 'flex',
												justifyContent: 'center',
												marginLeft: '0',
											}}
										> */}

										{order.data.status === 'READY_FOR_DISPATCH'
											? 'READY FOR DISPATCH'
											: order.data.status}
										{/* {order?.data?.status} */}
										{/* </div> */}
									</IonChip>
									{/* )} */}
								</div>
								<div
									style={{
										overflowX: 'auto',
										overflowY: 'hidden',
									}}
								>
									<div
										style={{
											minWidth: '520px',
											overflowX: 'auto',
										}}
									>
										<IonList>
											<IonItem>
												<IonGrid>
													<IonRow className="ion-align-items-center ion-text-center ">
														<IonCol size="4" className="ioncol-style">
															<IonLabel className="ion-margin-start">
																<h2
																	style={{
																		fontWeight: 'bold',
																	}}
																>
																	Product
																</h2>
															</IonLabel>
														</IonCol>
														<IonCol size="8">
															<IonItem>
																<IonGrid>
																	<IonRow className="ion-text-center ">
																		<IonCol size="12">
																			<IonLabel>
																				<h2
																					style={{
																						fontWeight: 'bold',
																					}}
																				>
																					Counts
																				</h2>
																			</IonLabel>
																		</IonCol>
																	</IonRow>
																</IonGrid>
															</IonItem>
															<IonGrid>
																<IonRow>
																	<IonCol size="3" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Pickup
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="3" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Verify
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="3" className={'ioncol-style'}>
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Dispatch
																			</h2>
																		</IonLabel>
																	</IonCol>
																	<IonCol size="3">
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Delivered
																			</h2>
																		</IonLabel>
																	</IonCol>
																</IonRow>
															</IonGrid>
														</IonCol>
														{/* <IonCol size="3" sizeMd="2">
															<IonLabel className="ion-margin-start">
																<h2
																	style={{
																		fontWeight: 'bold',
																	}}
																>
																	S.No.
																</h2>
															</IonLabel>
														</IonCol>
														<IonCol
															size={isApproved ? '2' : '5'}
															sizeMd={isApproved ? '5' : '7'}
														>
															<IonLabel>
																<h2
																	style={{
																		fontWeight: 'bold',
																	}}
																>
																	Item
																</h2>
															</IonLabel>
														</IonCol>
														<IonCol
															size={isApproved ? '3' : '4'}
															sizeMd={isApproved ? '2' : '3'}
														>
															<IonLabel className="ion-text-center">
																<h2
																	style={{
																		fontWeight: 'bold',
																	}}
																>
																	Count
																</h2>
															</IonLabel>
														</IonCol>

														{isApproved && (
															<IonCol size="4" sizeMd="3">
																<IonLabel className="ion-text-center">
																	<h2
																		style={{
																			fontWeight: 'bold',
																		}}
																	>
																		Verification Count
																	</h2>
																</IonLabel>
															</IonCol>
														)} */}
													</IonRow>
												</IonGrid>
											</IonItem>
										</IonList>
										{order?.data?.order_products.map(
											(product: any, index: number) => {
												return (
													<IonList key={product.id} className="ion-no-padding">
														<IonItem lines="none">
															<IonGrid>
																<IonRow className="ion-align-items-center">
																	<IonCol
																		className="ion-align-items-center ion-justify-content-center ion-text-center ioncol-style"
																		size="4"
																		style={{
																			display: 'flex',
																		}}
																	>
																		<IonThumbnail className="ion-margin-end ion-hide-sm-down">
																			<IonImg src={product.image_display_url} />
																		</IonThumbnail>
																		<IonLabel className="ion-text-wrap ">
																			{product.name}
																		</IonLabel>
																	</IonCol>
																	{/* Pick up column */}
																	<IonCol size="2" className="ioncol-style ">
																		<IonItem
																			lines={'none'}
																			disabled={order?.data?.status !== 'NEW'}
																		>
																			<IonLabel className="ion-text-center">
																				{product.count_pickup}
																			</IonLabel>
																		</IonItem>
																	</IonCol>
																	{/* Verify column */}
																	<IonCol
																		size="2"
																		className="ioncol-style ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		{/* if status is approved, then verify column will enabled */}
																		<IonItem
																			lines={isApproved ? 'full' : 'none'}
																			disabled={!isApproved}
																		>
																			{isApproved ? (
																				<IonLabel>
																					<Controller
																						// disabled={!isApproved}
																						control={control}
																						// rules={{
																						// 	min: {
																						// 		value: 1,
																						// 		message: 'Minimum 1',
																						// 	},
																						// 	max: {
																						// 		value: product.count_pickup,
																						// 		message: `Maximum ${product.count_pickup}`,
																						// 	},
																						// }}
																						name={`order_products.${index}.count_verification`}
																						render={({ field }) => (
																							<IonInput
																								className="ion-text-center"
																								value={field.value}
																								style={{ width: '100%' }}
																								onIonChange={e => {
																									if (
																										!isNaN(
																											Number(e.target.value)
																										)
																									) {
																										field.onChange(
																											Number(e.detail.value)
																										);
																									} else {
																										setError(
																											`order_products.${index}.count_verification`,
																											{
																												type: 'pattern',
																												message: 'Only numbers',
																											}
																										);
																									}
																								}}
																							/>
																						)}
																					/>
																					<ErrorMessage
																						errors={errors}
																						name={`order_products.${index}.count_verification`}
																						render={({ message }) => (
																							<IonText
																								color="danger"
																								className="ion-padding-horizontal"
																								style={{
																									fontSize: 12 + 'px',
																								}}
																							>
																								{message}
																							</IonText>
																						)}
																					/>
																				</IonLabel>
																			) : (
																				<IonLabel
																					style={{
																						textAlign: 'center',
																					}}
																				>
																					{product?.count_verification ||
																						' -- '}
																				</IonLabel>
																			)}
																		</IonItem>
																	</IonCol>
																	{/* Dispatch column */}
																	<IonCol
																		size="2"
																		className="ioncol-style ion-justify-content-center ion-align-items-center"
																		style={{
																			display: 'flex',
																		}}
																	>
																		{/* If status is verified, then it will be enabled */}
																		<IonItem
																			lines={isVerified ? 'full' : 'none'}
																			disabled={!isVerified}
																		>
																			{isVerified ? (
																				<IonLabel>
																					<Controller
																						control={control}
																						// rules={{
																						// 	min: {
																						// 		value: 1,
																						// 		message: 'Minimum 1',
																						// 	},
																						// 	max: {
																						// 		value: product.count_pickup,
																						// 		message: `Maximum ${product.count_pickup}`,
																						// 	},
																						// }}
																						name={`order_products.${index}.count_ready_for_dispatch`}
																						render={({ field }) => (
																							<IonInput
																								className="ion-text-center"
																								value={field.value}
																								style={{ width: '100%' }}
																								onIonChange={e => {
																									if (
																										!isNaN(
																											Number(e.target.value)
																										)
																									) {
																										field.onChange(
																											Number(e.detail.value)
																										);
																									} else {
																										setError(
																											`order_products.${index}.count_ready_for_dispatch`,
																											{
																												type: 'pattern',
																												message: 'Only numbers',
																											}
																										);
																									}
																								}}
																							/>
																						)}
																					/>
																					<ErrorMessage
																						errors={errors}
																						name={`order_products.${index}.count_ready_for_dispatch`}
																						render={({ message }) => (
																							<IonText
																								color="danger"
																								className="ion-padding-horizontal"
																								style={{
																									fontSize: 12 + 'px',
																								}}
																							>
																								{message}
																							</IonText>
																						)}
																					/>
																				</IonLabel>
																			) : (
																				<IonLabel className="ion-text-center">
																					{product?.count_ready_for_dispatch ||
																						' -- '}
																				</IonLabel>
																			)}
																		</IonItem>
																	</IonCol>
																	{/* Delivered column */}
																	<IonCol size="2">
																		<IonItem lines={'none'} disabled>
																			<IonLabel className="ion-text-center">
																				{' -- '}
																				{/* {product?.count_out_for_delivery ||
																					product?.count_pickup} */}
																				{/* <Controller
																			disabled
																			control={control}
																			defaultValue={
																				product.count_ready_for_dispatch
																			}
																			rules={{
																				min: {
																					value: 1,
																					message: 'Minimum 1',
																				},
																				max: {
																					value: product.count_pickup,
																					message: `Maximum ${product.count_pickup}`,
																				},
																			}}
																			name={`order_products.${index}.count_out_for_delivery`}
																			render={({ field }) => (
																				<IonInput
																					className="ion-text-center"
																					value={field.value}
																					style={{ width: '100%' }}
																					onIonChange={e => {
																						if (
																							!isNaN(Number(e.target.value))
																						) {
																							field.onChange(
																								Number(e.detail.value)
																							);
																						} else {
																							setError(
																								`order_products.${index}.count_out_for_delivery`,
																								{
																									type: 'pattern',
																									message: 'Only numbers',
																								}
																							);
																						}
																					}}
																				/>
																			)}
																		/>
																		<ErrorMessage
																			errors={errors}
																			name={`order_products.${index}.count_ready_for_dispatch`}
																			render={({ message }) => (
																				<IonText
																					color="danger"
																					className="ion-padding-horizontal"
																					style={{
																						fontSize: 12 + 'px',
																					}}
																				>
																					{message}
																				</IonText>
																			)}
																		/> */}
																			</IonLabel>
																		</IonItem>
																	</IonCol>
																</IonRow>
															</IonGrid>
														</IonItem>
													</IonList>
												);
											}
										)}
									</div>
								</div>

								<br />
								<IonGrid className="ion-text-center ion-margin-vertical">
									{isVerified && (
										<IonButton
											shape="round"
											type="submit"
											disabled={orderLoading || updateOrderLoading}
											onClick={() => {
												// setIsVerified(false);
											}}
										>
											<IonText color={'light'}>Dispatch</IonText>
										</IonButton>
									)}
									{isApproved && (
										<IonButton
											shape="round"
											type="submit"
											disabled={orderLoading || updateOrderLoading}
											// onClick={() => {
											// 	setIsVerified(true);
											// 	setIsApproved(false);
											// }}
										>
											<IonText color={'light'}>Verify</IonText>
										</IonButton>
									)}
								</IonGrid>
							</IonCardContent>
						</IonCard>
					</form>
				) : (
					<IonText
						className={`ion-align-items-center ion-text-center ${
							orderLoading === true ? 'ion-hide' : ''
						}`}
					>
						<h5>No order for your Action.</h5>
					</IonText>
				)}
				<IonLoading
					isOpen={orderLoading || updateOrderLoading}
					message={'Please wait...'}
				/>
				<IonAlert
					isOpen={showAlert === true}
					onDidDismiss={() => setShowAlert(false)}
					header={`Confirm ${
						isVerified ? 'Ready for dispatch' : 'Verify'
					} Order`}
					message={`I've verified the count for all products`}
					cssClass={'custom-alert'}
					buttons={[
						{
							cssClass: '',
							text: 'CANCEL',
							role: 'cancel',
						},
						{
							cssClass: 'alert-button-confirm',
							text: 'OK',
							handler: handleSubmit(onSubmit),
						},
					]}
				/>
			</IonContent>
			<IonToast
				isOpen={showToast}
				message={toastMessage}
				onDidDismiss={() => {
					setToastMessage('');
					setShowToast(false);
				}}
				duration={5000}
				position="top"
			></IonToast>
		</IonPage>
	);
};

export default OrderDetails;
