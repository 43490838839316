interface TokensObject {
	access_token: string;
	expires_in: number;
	refresh_token: string;
	token_type: string;
}

export const setTokensObject = (token: TokensObject) => {
	localStorage.setItem('Token_object', JSON.stringify(token));
};

export const getAccessToken = () => {
	const itemStr = localStorage.getItem('Token_object');
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	// const item = itemStr;
	// return item.access_token;
	return item.token;
};

export const getTokenObject = () => {
	const itemStr = localStorage.getItem('Token_object');
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	return item;
};

export const deleteTokenObject = () => {
	localStorage.removeItem('Token_object');
};
