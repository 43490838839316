import {
	IonContent,
	IonHeader,
	IonList,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React from 'react';
import ShortInfo from './ShortInfo';

type OrderListProps = {
	orders: any;
};

const OrderList: React.FC<OrderListProps> = ({ orders }) => {
	return (
		<IonList className="responsive-width">
			{orders?.map(order => (
				<ShortInfo key={order.id} order={order} />
			))}
		</IonList>
	);
};

export default OrderList;
