import React from 'react';
import {
	IonBadge,
	IonCard,
	IonLabel,
	IonButton,
	IonIcon,
	IonItem,
	IonText,
	IonImg,
	IonChip,
} from '@ionic/react';
import { call as callIcon } from 'ionicons/icons';
import { formatDate } from '../../services/formatters';
import { Link } from 'react-router-dom';

const ShortInfo = props => {
	const { order } = props;
	const color = {
		New: '#08da4e',
		Processing: '#f68c09',
		Delivered: '#2db6fa',
		Returned: '#e9222c',
		Cancelled: '#e9222c',
	};
	const paymentStatusColor = {
		PAID: 'warning',
		COD: 'danger',
	};
	// console.log(order);
	return (
		<div key={order.id}>
			<IonCard key={order.id} className="ion-margin-top">
				<IonItem lines="none">
					<IonText slot="start">
						<h5>Order #{order.id}</h5>
					</IonText>
					<IonText slot="end">
						<h5>{formatDate(order.created_at, 'DD/MM/YY h:mm A')}</h5>
					</IonText>
				</IonItem>
				<IonItem>
					{order?.business && order?.business?.logo_display_url && (
						<IonImg
							slot="start"
							src={order.business?.logo_display_url}
							style={{ width: 15 + '%', height: 15 + 'vh' }}
						></IonImg>
					)}
					<IonItem
						lines="none"
						className="ion-no-padding"
						style={{ width: 100 + '%' }}
					>
						<IonText className="ion-no-padding">
							{order.order_products[0] && (
								<h5>
									{order.order_products.length} Product
									{order.order_products.length > 1 ? 's' : ''},{' '}
									{order.order_products.reduce(
										(total: number, product: any) =>
											total + product.count_pickup,
										0
									)}{' '}
									Item
									{order.order_products.reduce(
										(total: number, product: any) =>
											total + product.count_pickup,
										0
									) > 1
										? 's'
										: ''}
								</h5>
							)}
							<h6>{order.business?.name}</h6>
						</IonText>
						{order?.status && (
							<IonChip slot="end" color={'secondary'}>
								{order.status === 'READY_FOR_DISPATCH'
									? 'READY FOR DISPATCH'
									: order.status}
							</IonChip>
						)}
					</IonItem>
				</IonItem>
				<IonItem lines="none">
					{/* <IonText slot="start">
						<b>{order.status}</b>
					</IonText> */}
					<IonItem lines="none" slot="end" className="ion-no-padding">
						{/* <IonButton href={`tel:${order.user_phone}`}> 
							<IonIcon icon={callIcon}></IonIcon>
						</IonButton>*/}
						<Link
							to={{
								pathname: `/orders/order-details/${order.id}`,
								state: order.id,
							}}
						>
							<IonButton shape="round">
								<IonText color={'light'}>Details &gt;</IonText>
							</IonButton>
						</Link>
					</IonItem>
				</IonItem>
			</IonCard>
		</div>
	);
};

export default ShortInfo;
