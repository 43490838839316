import {
	IonButton,
	IonButtons,
	IonCard,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonPopover,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTextarea,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';
import {
	arrowBack as arrowBackIcon,
	informationCircle as informationCircleIcon,
	addOutline as addOutlineIcon,
	close as closeIcon,
	closeOutline,
	removeCircle as removeCircleIcon,
	addCircle as addCircleIcon,
	caretDown as caretDownIcon,
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { getStoresCategory } from '../actions/categoryAction';
import { createProduct } from '../actions/productActions';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { ObjectUnsubscribedError } from 'rxjs';
import { API_ROOT } from '../constants/global';

async function savePicture(file) {
	if (!file) return '';
	const formData = new FormData();
	formData.append('file', file);
	try {
		const response = await fetch(`${API_ROOT}upload/product-image`, {
			method: 'POST',
			body: formData,
		});
		const data = await response.json();
		return data.platform_path;
	} catch (error) {
		return '';
	}
}

// const KeyCodes = {
//   comma: 188,
//   enter: [10, 13],
// };

// const delimiters = [...KeyCodes.enter, KeyCodes.comma];

// const placeholderlist = {
// 	Color: 'eg. Red, Blue, Green',
// 	Size: 'eg. Small, Medium, Large',
// 	Material: 'eg. Cotton, Wool, Polyester',
// 	Weight: 'eg. 500gm, 1Kg',
// 	'Add yours': 'Add yours',
// };
const AddProduct = (props: any) => {
	const [loading, setLoading] = useState(false);
	// const [showModal, setShowModal] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');

	const dispatch = useDispatch();
	// const { currentUser } = useSelector((state: any) => state.currentUser);
	// const { fetchCategoriesLoading, categories, fetchCategoriesError } =
	// 	useSelector((state: any) => state.categories);
	// const { createCategoryLoading, createCategory, createCategoryError } =
	// 	useSelector((state: any) => state.categories);

	const { createProductLoading, createProductMessage, createProductError } =
		useSelector((state: any) => state.createProductData);

	// var categoryFromProps;
	// if (props && props.location && props.location.state) {
	// 	categoryFromProps = props.location.state;
	// }
	// console.log(createProductMessage, ' createProductMessage');
	// console.log(createProductError, ' createProductError');
	const history = useHistory();

	//states to store form data
	const [productName, setProductName] = useState<any>('');
	const [productStatus, setProductStatus] = useState('active');
	const [weight, setWeight] = useState<string>();
	const [servicePrice, setServicePrice] = useState<string>();
	// TODO:
	const [dimension, setDimension] = useState<string>('');
	const [helpText, setHelpText] = useState<string>('');
	const [internalNotes, setInternalNotes] = useState<string>('');

	//states to check empty or not
	const [isProductNameEmpty, setIsProductNameEmpty] = useState<boolean>(false);
	const [isServicePriceEmpty, setIsServicePriceEmpty] =
		useState<boolean>(false);
	const [isImageEmpty, setIsImageEmpty] = useState<boolean>(false);
	const [isWeightEmpty, setIsWeightEmpty] = useState<boolean>(false);
	const [isDimensionEmpty, setIsDimensionEmpty] = useState<boolean>(false);
	const [isHelpTextEmpty, setIsHelpTextEmpty] = useState<boolean>(false);
	const [isInternalNotesEmpty, setIsInternalNotesEmpty] =
		useState<boolean>(false);

	//states to validate
	const [isServicePriceValidate, setIsServicePriceValidate] =
		useState<boolean>(true);
	const [isImageValidate, setIsImageValidate] = useState<boolean>(true);
	const [isWeightValidate, setIsWeightValidate] = useState<boolean>(true);

	const [platformPath, setPlatformPath] = useState<any>('');

	const fileInputRef = useRef<HTMLInputElement>();
	const [pictureUrl, setPictureUrl] = useState('/assets/placeholder.svg');

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.files.length > 0) {
			const file = event.target.files.item(0);
			if (file.type.startsWith('image')) {
				setIsImageEmpty(false);
				let temp2 = true;
				setIsImageValidate(temp2);
				const myPictureUrl = URL.createObjectURL(file);
				let temp3 = myPictureUrl;
				setPictureUrl(temp3);
				setLoading(true);
				const myPlatformPath = await savePicture(file);
				setLoading(false);
				if (myPlatformPath) {
					let temp4 = myPlatformPath;
					setPlatformPath(temp4);
				}
			} else {
				let temp2 = false;
				setIsImageValidate(temp2);
			}
		}
	};

	const handlePictureClick = async () => {
		if (isPlatform('capacitor')) {
			try {
				const photo = await Camera.getPhoto({
					resultType: CameraResultType.Uri,
				});
				let temp = photo.webPath;
				setPictureUrl(temp);
			} catch (error) {
				console.log(error);
			}
		} else {
			fileInputRef.current.click();
		}
	};

	//validate functions
	const productNameValidation = () => {
		let result = true;
		if (productName.length < 3) {
			setIsProductNameEmpty(true);
			result = false;
		} else {
			setIsProductNameEmpty(false);
		}
		return result;
	};

	const imageValidation = () => {
		let result = true;
		if (!platformPath) {
			setIsImageEmpty(true);
			// console.log(platformPath);
			result = false;
		} else {
			setIsImageEmpty(false);
		}
		return result;
	};
	const priceValidation = () => {
		let result = true;
		if (!servicePrice) {
			setIsServicePriceEmpty(true);
			result = false;
		} else {
			setIsServicePriceEmpty(false);
			if (!/^\d+(\.\d{1,2})?$/.test(servicePrice)) {
				setIsServicePriceValidate(false);
				result = false;
			} else {
				setIsServicePriceValidate(true);
			}
		}
		return result;
	};

	const weightValidation = () => {
		// Initialize result variable
		let result = true;
		// Check if weight is empty
		if (!weight) {
			setIsWeightEmpty(true);
			result = false;
		} else {
			setIsWeightEmpty(false);
			// Check if weight matches the required format
			if (!/^\d+(\.\d{1,2})?$/.test(weight)) {
				setIsWeightValidate(false);
				result = false;
			} else {
				setIsWeightValidate(true);
			}
		}
		return result;
	};

	//  Add validation for dimension
	const dimensionValidation = () => {
		let result = true;
		if (!dimension.length) {
			setIsDimensionEmpty(true);
			result = false;
		} else {
			setIsDimensionEmpty(false);
		}
		return result;
	};

	//Add validation for help_text
	const helpTextValidation = () => {
		let result = true;
		if (!helpText.length) {
			setIsHelpTextEmpty(true);
			result = false;
		} else {
			setIsHelpTextEmpty(false);
		}
		return result;
	};

	// Add validation for internal_notes
	const internalNotesValidation = () => {
		let result = true;
		if (!internalNotes.length) {
			setIsInternalNotesEmpty(true);
			result = false;
		} else {
			setIsInternalNotesEmpty(false);
		}
		return result;
	};

	const addProductFunction = async () => {
		// console.log('name ', productName);
		// console.log('status ', productStatus);
		// console.log('platformPath ', platformPath);
		// sessionStorage.setItem('newProductAdded', String(true));
		const accessToken = await getAccessToken();
		const res1 = productNameValidation();
		const res2 = imageValidation();
		const res3 = priceValidation();
		const res4 = weightValidation();
		const res5 = dimensionValidation();
		const res6 = helpTextValidation();
		const res7 = internalNotesValidation();

		if (res1 && res2 && res3 && res4 && res5 && res6 && res7 && accessToken) {
			console.log('everything is fine');
			const newProduct = {
				id: 0,
				name: productName,
				image: platformPath,
				status: productStatus,
				weight_in_gms: weight,
				laundry_service_price: servicePrice,
				dimension: dimension,
				help_text: helpText,
				internal_notes: internalNotes,
			};
			// console.log(newProduct);
			dispatch(createProduct(newProduct, accessToken));
		}
	};

	useEffect(() => {
		setLoading(false);
		if (createProductMessage?.id) {
			setShowToast(true);
			setToastMessage('Product Added Successfully');
			setProductName('');
			setPlatformPath('');
			setProductStatus('active');
			setPictureUrl('/assets/placeholder.svg');
			setWeight('');
			setServicePrice('');
			setDimension('');
			setHelpText('');
			setInternalNotes('');

			// console.log('Product Added Successfully');
			// console.log(createProductMessage);
		}
		if (createProductError) {
			//if any error happened
			setShowToast(true);
			setToastMessage('Something went wrong');
		}
	}, [createProductMessage, createProductError]);
	// console.log(createProductLoading, ' createProductLoading');
	// console.log(loading);
	// console.log(isFormSubmitted);
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
						/>
					</IonButtons>
					<IonTitle>Add Product</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<div
					style={{
						maxWidth: '80%',
						margin: '0 auto',
						border: '1px solid #eee',
					}}
					className="ion-padding"
				>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonCard
									style={{
										cursor: 'pointer',
										width: 150 + 'px',
										height: 120 + 'px',
										margin: '0 auto',
									}}
								>
									<input
										hidden
										type="file"
										accept="image/*"
										ref={fileInputRef}
										onChange={handleFileChange}
									/>
									<img
										alt=""
										width="100%"
										height="100%"
										src={pictureUrl}
										onClick={e => {
											handlePictureClick();
										}}
									></img>
								</IonCard>
							</IonCol>
							{/* <IonCol className={`${numberOfImage > 0 ? '' : 'ion-hide'}`}>
							<IonCard
								style={{
									cursor: 'pointer',
									width: 150 + 'px',
									height: 120 + 'px',
								}}
							>
								<input
									hidden
									type="file"
									accept="image/*"
									ref={fileInputRef}
									onChange={handleFileChange}
								/>
								<img
									alt=""
									width="100%"
									height="100%"
									src={pictureUrl[1]}
									onClick={e => {
										handlePictureClick();
										setImageClicked(1);
									}}
								></img>
							</IonCard>
						</IonCol>
						<IonCol className={`${numberOfImage > 1 ? '' : 'ion-hide'}`}>
							<IonCard
								style={{
									cursor: 'pointer',
									width: 150 + 'px',
									height: 120 + 'px',
								}}
							>
								<input
									hidden
									type="file"
									accept="image/*"
									ref={fileInputRef}
									onChange={handleFileChange}
								/>
								<img
									alt=""
									width="100%"
									height="100%"
									src={pictureUrl[2]}
									onClick={e => {
										handlePictureClick();
										setImageClicked(2);
									}}
								></img>
							</IonCard>
						</IonCol>
						<IonCol className={`${numberOfImage > 2 ? '' : 'ion-hide'}`}>
							<IonCard
								style={{
									cursor: 'pointer',
									width: 150 + 'px',
									height: 120 + 'px',
								}}
							>
								<input
									hidden
									type="file"
									accept="image/*"
									ref={fileInputRef}
									onChange={handleFileChange}
								/>
								<img
									alt=""
									width="100%"
									height="100%"
									src={pictureUrl[3]}
									onClick={e => {
										handlePictureClick();
										setImageClicked(3);
									}}
								></img>
							</IonCard>
						</IonCol> */}
						</IonRow>
					</IonGrid>
					<IonText
						color="danger"
						className={`${isImageValidate === true ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Only image files are allowed.
						</h5>
					</IonText>
					<IonText
						color="danger"
						className={`${isImageEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Minimum one image is required.
						</h5>
					</IonText>

					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Product Name</IonLabel>
						<IonInput
							placeholder="Enter product name"
							type="text"
							value={productName}
							onIonChange={e => {
								setProductName(e.detail.value);
								// if (e.detail.value.length)
								setIsProductNameEmpty(false);
								// else setIsProductNameEmpty(true);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${!isProductNameEmpty ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Product name must have minimum 3 characters.
						</h5>
					</IonText>
					<br />
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Service Price</IonLabel>
						<IonInput
							placeholder="Enter selling price"
							type="text"
							value={servicePrice}
							onIonChange={e => {
								setServicePrice(e.detail.value);
								setIsServicePriceValidate(true);
								// if (e.detail.value.length)
								setIsServicePriceEmpty(false);
								// else setIsServicePriceEmpty(true);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${
							!isServicePriceEmpty && isServicePriceValidate ? 'ion-hide' : ''
						}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isServicePriceEmpty ? '' : 'ion-hide'
							}`}
						>
							Service price is required.
						</h5>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isServicePriceValidate ? 'ion-hide' : ''
							}`}
						>
							Service price must contain only numbers and 2 digits after
							decimal.
						</h5>
					</IonText>
					<br />
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Weight (in gms)</IonLabel>
						<IonInput
							placeholder="Enter Weight in grams"
							type="text"
							value={weight}
							onIonChange={e => {
								setWeight(e.detail.value);
								setIsWeightValidate(true);
								// if (e.detail.value.length) {
								setIsWeightEmpty(false);
								// } else setIsWeightEmpty(true);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${
							!isWeightEmpty && isWeightValidate ? 'ion-hide' : ''
						}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isWeightEmpty ? '' : 'ion-hide'
							}`}
						>
							Service price is required.
						</h5>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isWeightValidate ? 'ion-hide' : ''
							}`}
						>
							Weight must contain only numbers and 2 digits after decimal.
						</h5>
					</IonText>
					<br />
					{/* Dimension */}
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Dimension</IonLabel>
						<IonInput
							placeholder="Enter Dimension"
							type="text"
							value={dimension}
							onIonChange={e => {
								setDimension(e.detail.value);
								// if (e.detail.value.length)
								setIsDimensionEmpty(false);
								// else setIsDimensionEmpty(true);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isDimensionEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Dimension can not be empty.
						</h5>
					</IonText>
					<br />
					{/* Help Text */}
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Help Text</IonLabel>
						<IonInput
							placeholder="Enter help text here"
							type="text"
							value={helpText}
							onIonChange={e => {
								setHelpText(e.detail.value);
								// if (e.detail.value.length) {
								setIsHelpTextEmpty(false);
								// } else {
								// setIsHelpTextEmpty(true);
								// }
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isHelpTextEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Help Text can not be empty.
						</h5>
					</IonText>
					<br />
					<IonItem className="ion-margin-bottom">
						<IonLabel position="stacked">Internal Notes</IonLabel>
						<IonInput
							placeholder="Enter internal notes here..."
							type="text"
							value={internalNotes}
							onIonChange={e => {
								setInternalNotes(e.detail.value);
								// if (e.detail.value.length)
								setIsInternalNotesEmpty(false);
								// else setIsInternalNotesEmpty(true);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${isInternalNotesEmpty === false ? 'ion-hide' : ''}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className="ion-margin-horizontal"
						>
							Internal Notes can not be empty.
						</h5>
					</IonText>
					<br />
					<IonItem>
						<IonLabel>Product Status</IonLabel>
						<IonSelect
							slot="end"
							value={productStatus}
							onIonChange={e => setProductStatus(e.detail.value)}
						>
							<IonSelectOption value="active">Active</IonSelectOption>
							<IonSelectOption value="inactive">Inactive</IonSelectOption>
						</IonSelect>
					</IonItem>

					<br />
					<div className="ion-text-center">
						<IonButton shape="round" onClick={addProductFunction}>
							Add Product
						</IonButton>
					</div>

					<IonLoading isOpen={!!createProductLoading} message="Please Wait" />
				</div>

				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={() => {
						setToastMessage('');
						setShowToast(false);
					}}
					duration={2000}
					position="top"
				></IonToast>
			</IonContent>
		</IonPage>
	);
};

export default AddProduct;
