import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	IonRow,
	IonGrid,
	IonCol,
	IonCard,
	IonIcon,
	IonItem,
	IonSelect,
	IonSelectOption,
	IonToast,
	IonLoading,
	IonPopover,
	IonImg,
	IonButton,
	IonCardHeader,
	IonText,
	IonCardTitle,
	IonCardContent,
	IonTitle,
	IonHeader,
	IonList,
	IonLabel,
	IonCardSubtitle,
	IonModal,
	IonToolbar,
	IonContent,
	IonButtons,
	IonPage,
} from '@ionic/react';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import {
	cartOutline as cartIcon,
	people as peopleIcon,
	basketOutline as basketIcon,
	copyOutline as copyIcon,
	shareSocialOutline as shareIcon,
	globeOutline as globeIcon,
	logoAndroid as androidIcon,
	bicycleOutline as cycleIcon,
	fileTrayFull as fileTrayFullIcon,
	eyedrop,
	eyeOutline,
	informationCircleOutline,
	bagAddOutline,
	appsOutline,
	globeOutline,
	gridOutline,
	manOutline,
	colorPaletteOutline,
	copyOutline,
	logoWhatsapp as whatsappIcon,
	caretForwardOutline,
	closeOutline,
	closeSharp,
} from 'ionicons/icons';
import {
	getHomeScreenDataWithDays,
	getLandingPages,
} from '../../actions/dashboardActions';
// import { authenticateUserWhenLogout } from "../../actions/authActions";
import { ShareButtonMobile } from '../ShareButtonMobile';
import { ShareButtonWeb } from '../ShareButtonWeb';
import AiImage from '../../images/ai.gif';
import newImg from '../../images/new.png';
import QRCodeImage from '../../images/qr-code-sample.png';
import OpenSlide from './OpenSlide';
import { getPlatformId } from '@capacitor/core/types/util';

const HomeScreen = () => {
	const [showURLToast, setShowURLToast] = useState(false);
	const [showCodeToast, setShowCodeToast] = useState(false);
	const [showWebsiteToast, setShowWebsiteToast] = useState(false);
	const [showAppToast, setShowAppToast] = useState(false);
	const [showLandingToast, setShowLandingToast] = useState(false);

	const [selectedValue, setSelectedValue] = useState('Anytime');
	const [landingPagesURL, setLandingPagesURL] = useState();

	// added
	const whatsappText = encodeURIComponent('Hey there, I need your help');
	const whatsappNo = '918448355548';
	const aboutSlidePath =
		'https://docs.google.com/presentation/d/1aHaCHxlmtaHrCSJnyCkWF9CsfZSdRllz6I4AAPzdopM/edit?usp=sharing';

	const distributorSlidePath =
		'https://docs.google.com/presentation/d/1HspRg-FU9aG874_A_ZbhMJ-cXfCHgB26K-Bci97Qdd8/edit?usp=sharing';

	const marketingSlidePath =
		'https://docs.google.com/presentation/d/1unClWzTCfKskh1z7Geup2Ksoav7BsjfDTzMHT0Gi68o/edit?usp=sharing';

	const faqSlidePath =
		'https://docs.google.com/presentation/d/1n-57l6eHtQIe97Rth6gDVkrwbg9SrdvFIJEj0NQAMjQ/edit?usp=sharing';

	const [path, setPath] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [modalTitle, setModalTitle] = useState('');

	const onClickBtn = (pathVar, title) => {
		setShowModal(true);
		setPath(pathVar);
		setModalTitle(title);
		console.log('open:', showModal, path);
	};

	const onCloseModal = () => {
		setShowModal(false);
		setPath('');
		console.log(showModal, path);
	};
	// end added

	const dispatch = useDispatch();
	const history = useHistory();
	const { currentUser } = useSelector((state: any) => state.currentUser);
	const { homeScreenLoading, homeScreen, homeScreenError } = useSelector(
		(state: any) => state.homeScreenData
	);
	const {
		homeScreenWithDaysLoading,
		homeScreenWithDays,
		homeScreenWithDaysError,
	} = useSelector((state: any) => state.homeScreenDataWithDays);
	const { landingPagesLoading, landingPages, landingPagesError } = useSelector(
		(state: any) => state.landingPages
	);

	const map = {
		'SCREEN.NONE': '/my/userdashboard',
		'SCREEN.HELP': '/my/help&support',
		'SCREEN.CUSTOMERS': '/my/customer',
		'SCREEN.LEARNGROW': '/my/ikacademy',
		'SCREEN.BANNERS': '/my/banners',
		'SCREEN.COUPONS': '/my/coupons',
		'SCREEN.CATALOG': '/my/catalogue',
		'SCREEN.SETTINGS.LOCALISATION': '/my/settings/localization',
		'SCREEN.SETTINGS.BASICINFO': '/my/settings/basicinfo',
	};
	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		const accessToken = getAccessToken();
	// 		await dispatch(
	// 			getHomeScreenDataWithDays(`Bearer ${accessToken}`, selectedValue)
	// 		);
	// 	};
	// 	fetchData();
	// }, [selectedValue]);

	// useEffect(() => {
	// 	dispatch(getLandingPages(getAccessToken()));
	// }, [currentUser]);

	// useEffect(() => {
	// 	if (landingPagesLoading === false && landingPages && landingPages.data) {
	// 		setLandingPagesURL(landingPages.data.partner_landing_page);
	// 	}
	// }, [landingPages]);

	const onSelectChange = async (e: any) => {
		setSelectedValue(e.detail.value);
	};

	const copyToClipboard = async (data: string) => {
		await Clipboard.write({
			string: `${data}`,
		});
	};

	const shareWebsite = async () => {
		await Share.share({});
	};

	const shareBusinessApp = async () => {
		await Share.share({
			title: 'Intelikart Business App',
			text: `${
				homeScreenWithDaysLoading === false &&
				homeScreenWithDays &&
				homeScreenWithDays.data &&
				homeScreenWithDays.data.app
					? homeScreenWithDays.data.app.message
					: 'My Business App'
			}`,
			url: `${
				homeScreenWithDaysLoading === false &&
				homeScreenWithDays &&
				homeScreenWithDays.data &&
				homeScreenWithDays.data.app
					? homeScreenWithDays.data.app.URL
					: ''
			}`,
		});
	};

	return (
		<IonContent scrollY={false}>
			<iframe
				title="clc"
				width="100%"
				height="100%"
				src="https://rss.app/embed/v1/feed/Yiqx8zwtYFlNebPv"
				frameBorder="{0}"
			></iframe>

			{/* <IonGrid className="ion-no-padding ion-no-margin">
				<IonRow>
					<IonCol sizeMd="8" sizeSm="12">
						<div className="dashboard-website">
							<IonCard className="">
								<IonCardContent>
									<IonGrid className="ion-no-padding ion-no-margin">
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
													Your Unique URL
												</IonText>
											</IonCol>
											<IonCol size="2">
												<IonButton
													fill="clear"
													color="secondary"
													onClick={e => {
														copyToClipboard(
															`${
																homeScreenWithDaysLoading === false &&
																homeScreenWithDays &&
																homeScreenWithDays.data
																	? homeScreenWithDays.data.web_store_manager
																	: 'Not Available'
															}`
														);
														setShowURLToast(true);
													}}
												>
													<IonIcon
														size="large"
														slot="icon-only"
														icon={copyIcon}
													></IonIcon>
												</IonButton>
												<IonIcon
													size="large"
													icon={copyIcon}
													onClick={e => {
														copyToClipboard(
															homeScreenWithDaysLoading === false &&
																homeScreenWithDays &&
																homeScreenWithDays.data &&
																homeScreenWithDays.data.website
																? homeScreenWithDays.data.website.URL
																: 'Not Available'
														);
														setShowWebToast(true);
													}}
												></IonIcon>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelink dashboard-fnt-sz">
													<a
														href={`${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.web_store_manager
																: ''
														}`}
														target="_blank"
													>
														{homeScreenWithDaysLoading === false &&
														homeScreenWithDays &&
														homeScreenWithDays.data
															? homeScreenWithDays.data.web_store_manager
															: ''}
													</a>
												</IonText>
											</IonCol>
											<IonCol size="2">
												<MobileView>
													<ShareButtonMobile
														title="Intelikart Business Website"
														text={`${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.web_store_manager
																: 'Web Manager URL'
														}`}
														url={`${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.web_store_manager
																: ''
														}`}
													/>
												</MobileView>
												<BrowserView>
													<ShareButtonWeb
														url={
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.web_store_manager
																: ''
														}
														text={`${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.web_store_manager
																: ''
														}`}
													/>
												</BrowserView>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</div>

						
						<div className="dashboard-website">
							<IonCard className="">
								<IonCardContent>
									<IonGrid className="ion-no-padding ion-no-margin">
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
													Your Unique Code
												</IonText>
											</IonCol>
											<IonCol size="2">
												<IonButton
													fill="clear"
													color="secondary"
													onClick={e => {
														copyToClipboard(
															homeScreenWithDaysLoading === false &&
																homeScreenWithDays &&
																homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: 'Not Available'
														);
														setShowCodeToast(true);
													}}
												>
													<IonIcon
														size="large"
														slot="icon-only"
														icon={copyIcon}
													></IonIcon>
												</IonButton>
											
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText
													className="websitelink"
													style={{
														fontWeight: 'bold',
														letterSpacing: '2px',
														fontSize: '20px',
													}}
												>
													{homeScreenWithDaysLoading === false &&
													homeScreenWithDays &&
													homeScreenWithDays.data
														? homeScreenWithDays.data.unique_code
														: ''}
												</IonText>
											</IonCol>
											<IonCol size="2">
												<MobileView>
													<ShareButtonMobile
														title="Intelikart Business Website"
														text={`${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: 'My Unique Code'
														}`}
														url={`${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
													/>
												</MobileView>
												<BrowserView>
													<ShareButtonWeb
														url={
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}
														text={`Please check out website for my business build using Intelikart.com ${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
													/>
												</BrowserView>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</div>

						<div className="dashboard-website">
							<IonCard className="">
								<IonCardContent>
									<IonGrid className="ion-no-padding ion-no-margin">
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
													Intelikart Website
												</IonText>
											</IonCol>
											<IonCol size="2">
												<IonButton
													fill="clear"
													color="secondary"
													onClick={e => {
														copyToClipboard(
															`https://www.intelikart.com/?ref=${
																homeScreenWithDaysLoading === false &&
																homeScreenWithDays &&
																homeScreenWithDays.data
																	? homeScreenWithDays.data.unique_code
																	: ''
															}`
														);
														setShowWebsiteToast(true);
													}}
												>
													<IonIcon
														size="large"
														slot="icon-only"
														icon={copyIcon}
													></IonIcon>
												</IonButton>
											
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelink dashboard-fnt-sz">
													<a
														href={`https://www.intelikart.com/?ref=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
														target="_blank"
													>
														https://www.intelikart.com/?ref=
														{homeScreenWithDaysLoading === false &&
														homeScreenWithDays &&
														homeScreenWithDays.data
															? homeScreenWithDays.data.unique_code
															: ''}
													</a>
												</IonText>
											</IonCol>
											<IonCol size="2">
												<MobileView>
													<ShareButtonMobile
														title="Intelikart Business Website"
														text={`https://www.intelikart.com/?ref=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
														url={`https://www.intelikart.com/?ref=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
													/>
												</MobileView>
												<BrowserView>
													<ShareButtonWeb
														url={`https://www.intelikart.com/?ref=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
														text={`https://www.intelikart.com/?ref=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
													/>
												</BrowserView>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</div>

						<div className="dashboard-website">
							<IonCard className="">
								<IonCardContent>
									<IonGrid className="ion-no-padding ion-no-margin">
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
													Intelikart App
												</IonText>
											</IonCol>
											<IonCol size="2">
												<IonButton
													fill="clear"
													color="secondary"
													onClick={e => {
														copyToClipboard(
															`https://play.google.com/store/apps/details?id=com.intelikart&referrer=${homeScreenWithDays.data.unique_code}`
														);
														setShowAppToast(true);
													}}
												>
													<IonIcon
														size="large"
														slot="icon-only"
														icon={copyIcon}
													></IonIcon>
												</IonButton>
											
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelink dashboard-fnt-sz">
													<a
														href={`https://play.google.com/store/apps/details?id=com.intelikart&referrer=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
														target="_blank"
													>
														https://play.google.com/store/apps/details?id=com.intelikart&referrer=
														{homeScreenWithDaysLoading === false &&
														homeScreenWithDays &&
														homeScreenWithDays.data
															? homeScreenWithDays.data.unique_code
															: ''}
													</a>
												</IonText>
											</IonCol>
											<IonCol size="2">
												<MobileView>
													<ShareButtonMobile
														title="Intelikart Business Website"
														text={`https://play.google.com/store/apps/details?id=com.intelikart&referrer=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
														url={`https://play.google.com/store/apps/details?id=com.intelikart&referrer=${
															homeScreenWithDaysLoading === false &&
															homeScreenWithDays &&
															homeScreenWithDays.data
																? homeScreenWithDays.data.unique_code
																: ''
														}`}
													/>
												</MobileView>
												<BrowserView>
													<ShareButtonWeb
														url={encodeURIComponent(
															`https://play.google.com/store/apps/details?id=com.intelikart&referrer=${
																homeScreenWithDaysLoading === false &&
																homeScreenWithDays &&
																homeScreenWithDays.data
																	? homeScreenWithDays.data.unique_code
																	: ''
															}`
														)}
														text={encodeURIComponent(
															`https://play.google.com/store/apps/details?id=com.intelikart&referrer=${
																homeScreenWithDaysLoading === false &&
																homeScreenWithDays &&
																homeScreenWithDays.data
																	? homeScreenWithDays.data.unique_code
																	: ''
															}`
														)}
													/>
												</BrowserView>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</div>

						<div className="dashboard-website">
							<IonCard className="">
								<IonCardContent>
									<IonGrid className="ion-no-padding ion-no-margin">
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
													Landing Pages URL
												</IonText>
											</IonCol>
											<IonCol size="2">
												<IonButton
													fill="clear"
													color="secondary"
													onClick={e => {
														copyToClipboard(
															`${landingPagesURL ? landingPagesURL : ''}`
														);
														setShowLandingToast(true);
													}}
												>
													<IonIcon
														size="large"
														slot="icon-only"
														icon={copyIcon}
													></IonIcon>
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol
												className="ion-no-padding ion-no-margin"
												size="10"
											>
												<IonText className="websitelink dashboard-fnt-sz">
													{landingPagesURL ? (
														<a href={landingPagesURL} target="_blank">
															{landingPagesURL}
														</a>
													) : (
														<a
															onClick={() => {
																history.push(`/my/landingpages`);
															}}
														>
															Setup Landing Page
														</a>
													)}
												</IonText>
											</IonCol>
											<IonCol size="2">
												<MobileView>
													<ShareButtonMobile
														title="Intelikart Business Website"
														text={`${landingPagesURL ? landingPagesURL : ''}`}
														url={`${landingPagesURL ? landingPagesURL : ''}`}
													/>
												</MobileView>
												<BrowserView>
													<ShareButtonWeb
														url={encodeURIComponent(
															`${landingPagesURL ? landingPagesURL : ''}`
														)}
														text={encodeURIComponent(
															`${landingPagesURL ? landingPagesURL : ''}`
														)}
													/>
												</BrowserView>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</div>

						{/* <div className="dashboard-website">
                            <IonCard className="">
                                <IonCardContent>
                                    <IonGrid className="ion-no-padding ion-no-margin">
                                        <IonRow>
                                            <IonCol
                                                className="ion-no-padding ion-no-margin"
                                                size="10"
                                            >
                                                <IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
                                                    Your Play Store Seller App URL
                                                </IonText>
                                            </IonCol>
                                            <IonCol size="2">
                                                <IonButton
                                                    fill="clear"
                                                    color="secondary"
                                                    onClick={(e) => {
                                                        copyToClipboard(
                                                            homeScreenWithDaysLoading ===
                                                                false &&
                                                                homeScreenWithDays &&
                                                                homeScreenWithDays.data
                                                                ? homeScreenWithDays
                                                                      .data
                                                                      .seller_app_url
                                                                : "Not Available"
                                                        );
                                                        setStoreAppToast(true);
                                                    }}
                                                >
                                                    <IonIcon
                                                        size="large"
                                                        slot="icon-only"
                                                        icon={copyIcon}
                                                    ></IonIcon>
                                                </IonButton>
                                                <IonIcon size="large" icon={copyIcon} onClick={(e) => { copyToClipboard(homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : "Not Available"); setShowWebToast(true); }}></IonIcon>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol
                                                className="ion-no-padding ion-no-margin"
                                                size="10"
                                            >
                                                <IonText className="websitelink dashboard-fnt-sz">
                                                    <a
                                                        href={`${
                                                            homeScreenWithDaysLoading ===
                                                                false &&
                                                            homeScreenWithDays &&
                                                            homeScreenWithDays.data
                                                                ? homeScreenWithDays
                                                                      .data
                                                                      .seller_app_url
                                                                : ""
                                                        }`}
                                                        target="_blank"
                                                    >
                                                        {homeScreenWithDaysLoading ===
                                                            false &&
                                                        homeScreenWithDays &&
                                                        homeScreenWithDays.data
                                                            ? homeScreenWithDays
                                                                  .data
                                                                  .seller_app_url
                                                            : ""}
                                                    </a>
                                                </IonText>
                                            </IonCol>
                                            <IonCol size="2">
                                                <MobileView>
                                                    <ShareButtonMobile
                                                        title="Intelikart Business Website"
                                                        text={`${
                                                            homeScreenWithDaysLoading ===
                                                                false &&
                                                            homeScreenWithDays &&
                                                            homeScreenWithDays.data
                                                                ? homeScreenWithDays
                                                                      .data
                                                                      .seller_app_url
                                                                : "Seller App URL"
                                                        }`}
                                                        url={`${
                                                            homeScreenWithDaysLoading ===
                                                                false &&
                                                            homeScreenWithDays &&
                                                            homeScreenWithDays.data
                                                                ? homeScreenWithDays
                                                                      .data
                                                                      .seller_app_url
                                                                : ""
                                                        }`}
                                                    />
                                                </MobileView>
                                                <BrowserView>
                                                    <ShareButtonWeb
                                                        url={
                                                            homeScreenWithDaysLoading ===
                                                                false &&
                                                            homeScreenWithDays &&
                                                            homeScreenWithDays.data
                                                                ? homeScreenWithDays
                                                                      .data
                                                                      .seller_app_url
                                                                : ""
                                                        }
                                                        text={`Please check out play store seller app for my business build using Intelikart.com ${
                                                            homeScreenWithDaysLoading ===
                                                                false &&
                                                            homeScreenWithDays &&
                                                            homeScreenWithDays.data
                                                                ? homeScreenWithDays
                                                                      .data
                                                                      .seller_app_url
                                                                : ""
                                                        }`}
                                                    />
                                                </BrowserView>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </div> {homeScreenWithDays &&
                            homeScreenWithDays.data &&
                            homeScreenWithDays.data.app &&
                            homeScreenWithDays.data.app.URL && (
                                <div className="dashboard-website">
                                    <IonCard className="">
                                        <IonCardContent>
                                            <IonGrid className="ion-no-padding ion-no-margin">
                                                <IonRow>
                                                    <IonCol
                                                        className="ion-no-padding ion-no-margin"
                                                        size="10"
                                                    >
                                                        <IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
                                                            Your Branded App
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol size="2">
                                                        <IonIcon
                                                            size="large"
                                                            icon={copyIcon}
                                                            onClick={(e) => {
                                                                copyToClipboard(
                                                                    homeScreenWithDaysLoading ===
                                                                        false &&
                                                                        homeScreenWithDays &&
                                                                        homeScreenWithDays.data &&
                                                                        homeScreenWithDays
                                                                            .data.app
                                                                        ? homeScreenWithDays
                                                                            .data.app.URL
                                                                        : "Not Available"
                                                                );
                                                                setShowWebToast(true);
                                                            }}
                                                        ></IonIcon>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol
                                                        className="ion-no-padding ion-no-margin"
                                                        size="10"
                                                    >
                                                        <IonText className="websitelink dashboard-fnt-sz">
                                                            <a
                                                                href={`${
                                                                    homeScreenWithDaysLoading ===
                                                                        false &&
                                                                    homeScreenWithDays &&
                                                                    homeScreenWithDays.data &&
                                                                    homeScreenWithDays.data
                                                                        .app
                                                                        ? homeScreenWithDays
                                                                            .data.app.URL
                                                                        : ""
                                                                }`}
                                                                target="_blank"
                                                            >
                                                                <BrowserView>
                                                                    {homeScreenWithDaysLoading ===
                                                                        false &&
                                                                    homeScreenWithDays &&
                                                                    homeScreenWithDays.data &&
                                                                    homeScreenWithDays.data
                                                                        .app
                                                                        ? homeScreenWithDays
                                                                            .data.app.URL
                                                                        : ""}
                                                                </BrowserView>
                                                                <MobileView>
                                                                    {homeScreenWithDaysLoading ===
                                                                        false &&
                                                                    homeScreenWithDays &&
                                                                    homeScreenWithDays.data &&
                                                                    homeScreenWithDays.data
                                                                        .app
                                                                        ? homeScreenWithDays.data.app.URL.substring(
                                                                            0,
                                                                            37
                                                                        ) + ".."
                                                                        : ""}
                                                                </MobileView>
                                                            </a>
                                                        </IonText>
                                                    </IonCol>
                                                    <IonCol size="2">
                                                        <MobileView>
                                                            <IonIcon
                                                                size="large"
                                                                icon={shareIcon}
                                                                onClick={(e) =>
                                                                    shareBusinessApp()
                                                                }
                                                            ></IonIcon>
                                                        </MobileView>
                                                        <BrowserView>
                                                            <IonIcon
                                                                size="large"
                                                                icon={globeIcon}
                                                            ></IonIcon>
                                                        </BrowserView>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                            )}

                        <div>
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="6">
                                        <IonButton color="warning" className="dashboard-cta">Get Custom Domain</IonButton>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonButton  color="warning" className="dashboard-cta">Get Android App</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>  <IonItem lines="none">
							<IonText>Your Growth Report</IonText>
							<IonSelect
								slot="end"
								onIonChange={e => onSelectChange(e)}
								value={selectedValue}
							>
								<IonSelectOption value="Anytime">Anytime</IonSelectOption>
								<IonSelectOption value="Monthly">Monthly</IonSelectOption>
								<IonSelectOption value="Weekly">Weekly</IonSelectOption>
								<IonSelectOption value="Today">Today</IonSelectOption>
							</IonSelect>
						</IonItem>  <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonCard
                                        className="ion-no-margin ion-no-padding"
                                        routerLink="/my/orders"
                                    >
                                        <IonCardHeader>
                                            <IonText className="dashboard-fnt-sz">
                                                Your Orders
                                            </IonText>
                                            <IonCardTitle className="fnt-sz28">
                                                {homeScreenWithDaysLoading === false &&
                                                homeScreenWithDays &&
                                                homeScreenWithDays.data
                                                    ? homeScreenWithDays.data.orders
                                                    : null}
                                            </IonCardTitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>
                                <IonCol>
                                    <IonCard
                                        className="ion-no-margin ion-no-padding"
                                        routerLink="/my/customer"
                                    >
                                        <IonCardHeader>
                                            <IonText className="dashboard-fnt-sz">
                                                Your Customers
                                            </IonText>
                                            <IonCardTitle className="fnt-sz28">
                                                {homeScreenWithDaysLoading === false &&
                                                homeScreenWithDays &&
                                                homeScreenWithDays.data
                                                    ? homeScreenWithDays.data.users
                                                    : null}
                                            </IonCardTitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid> <IonGrid>
							<IonRow>
								<IonCol>
									<IonCard className="ion-no-margin ion-no-padding">
										<IonCardHeader>
											<IonText className="dashboard-fnt-sz">Reach</IonText>
											<IonCardTitle className="fnt-sz28">
												{homeScreenWithDaysLoading === false &&
												homeScreenWithDays &&
												homeScreenWithDays.data
													? homeScreenWithDays.data.clicks
													: null}
											</IonCardTitle>
										</IonCardHeader>
									</IonCard>
								</IonCol>
								<IonCol>
									<IonCard className="ion-no-margin ion-no-padding">
										<IonCardHeader>
											<IonText className="dashboard-fnt-sz">Stores</IonText>
											<IonCardTitle className="fnt-sz28">
												{homeScreenWithDaysLoading === false &&
												homeScreenWithDays &&
												homeScreenWithDays.data
													? homeScreenWithDays.data.stores
													: null}
											</IonCardTitle>
										</IonCardHeader>
									</IonCard>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonCard className="ion-no-margin ion-no-padding">
										<IonCardHeader>
											<IonText className="dashboard-fnt-sz">
												Subscriptions
											</IonText>
											<IonCardTitle className="fnt-sz28">
												{homeScreenWithDaysLoading === false &&
												homeScreenWithDays &&
												homeScreenWithDays.data
													? homeScreenWithDays.data.subscriptions
													: null}
											</IonCardTitle>
										</IonCardHeader>
									</IonCard>
								</IonCol>
								<IonCol>
									<IonCard className="ion-no-margin ion-no-padding">
										<IonCardHeader>
											<IonText className="dashboard-fnt-sz">Revenue</IonText>
											<IonCardTitle className="fnt-sz28">
												{homeScreenWithDaysLoading === false &&
												homeScreenWithDays &&
												homeScreenWithDays.data
													? homeScreenWithDays.data.revenue
													: null}
											</IonCardTitle>
										</IonCardHeader>
									</IonCard>
								</IonCol>
							</IonRow>
						</IonGrid>
				 </IonCol> 

					<IonCol sizeMd="4" sizeSm="12">
						<IonCard>
							<IonCardHeader>
								<IonCardSubtitle className="ion-no-padding ion-no-margin">
									<IonText className="dashboard-fnt-sz">Useful Links</IonText>
								</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent className="ion-no-padding ion-no-margin">
								<IonList className="ion-no-padding ion-no-margin">
									<IonItem
										button
										target="_blank"
										href={`https://wa.me/${whatsappNo}?text=${whatsappText}`}
										detail={false}
									>
										<IonLabel>Help</IonLabel>
										<IonIcon icon={whatsappIcon} slot="end"></IonIcon>
									</IonItem>
									<IonItem
										button
										onClick={() =>
											onClickBtn(aboutSlidePath, 'About Intelikart')
										}
										detail={false}
									>
										<IonLabel>About Intelikart</IonLabel>
										<IonIcon icon={caretForwardOutline} slot="end"></IonIcon>
									</IonItem>
									<IonItem
										button
										onClick={() =>
											onClickBtn(distributorSlidePath, 'Partner Process')
										}
										detail={false}
									>
										<IonLabel>Partner Process</IonLabel>
										<IonIcon icon={caretForwardOutline} slot="end"></IonIcon>
									</IonItem>
									<IonItem
										button
										onClick={() =>
											onClickBtn(marketingSlidePath, 'Marketing Ideas')
										}
										detail={false}
									>
										<IonLabel>Marketing Ideas</IonLabel>
										<IonImg className="newimage" src={newImg}></IonImg>
										<IonIcon icon={caretForwardOutline} slot="end"></IonIcon>
									</IonItem>
									<IonItem
										button
										onClick={() => {
											history.push(`/my/features`);
										}}
										detail={false}
									>
										<IonLabel>Request Features</IonLabel>
										<IonIcon icon={caretForwardOutline} slot="end"></IonIcon>
									</IonItem>
									<IonItem
										button
										onClick={() => onClickBtn(faqSlidePath, 'FAQ')}
										detail={false}
									>
										<IonLabel>FAQ</IonLabel>
										<IonIcon icon={caretForwardOutline} slot="end"></IonIcon>
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>

						{/* <IonCard className="">
                            <IonCardContent>
                                <IonItem className="ion-text-center">
                                    <IonLabel>
                                        <IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">
                                            Your QR Code
                                        </IonText>
                                    </IonLabel>
                                    <IonLabel>
                                        <img
                                            src={QRCodeImage}
                                            alt="QR Code"
                                            style={{
                                                backgroundColor: "white",
                                                width: "70%",
                                            }}
                                        />
                                    </IonLabel>
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
					</IonCol>
				</IonRow>
			</IonGrid> 
			<IonModal
				isOpen={showModal}
				className="fullscreen"
				onDidDismiss={() => setShowModal(false)}
			>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={onCloseModal}>
								<IonIcon ios={closeOutline} md={closeSharp} />
							</IonButton>
						</IonButtons>
						<IonTitle className="ionTextCenter">{modalTitle}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<OpenSlide path={path} />
				</IonContent>
			</IonModal>
		 <IonGrid>
                <IonRow>
                    <IonCol>
                        {homeScreen.data && homeScreen.data.touch_target && (
                            <IonCard
                                style={{ margin: 0 }}
                                routerLink={`${
                                    map[homeScreen.data.touch_target]
                                }`}
                            >
                                <IonGrid
                                    style={{
                                        backgroundColor:
                                            "rgba(56, 128, 255, 0.14)",
                                    }}
                                >
                                    <IonRow>
                                        <IonCol size="2">
                                            <IonImg
                                                src={AiImage}
                                                className="aiimage"
                                            />
                                        </IonCol>
                                        <IonCol>
                                            <IonText color="primary">
                                                <h6
                                                    style={{
                                                        margin: 4 + "px",
                                                        marginLeft: 0,
                                                    }}
                                                >
                                                    {homeScreenLoading ===
                                                        false &&
                                                    homeScreen &&
                                                    homeScreen.data
                                                        ? homeScreen.data
                                                              .heading
                                                        : ""}
                                                </h6>
                                                {homeScreenLoading === false &&
                                                homeScreen &&
                                                homeScreen.data
                                                    ? homeScreen.data.subheading
                                                    : ""}
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCard>
                        )}
                    </IonCol>
                </IonRow>
            </IonGrid>  <div className="dashborad-grid-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="4">
                            <IonCard
                                className="ion-no-margin ion-no-padding ion-text-center"
                                routerLink="/my/products"
                            >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonIcon
                                            size="large"
                                            icon={eyeOutline}
                                        ></IonIcon>
                                    </IonCardTitle>
                                    <IonText>View Your Products</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard
                                className="ion-no-margin ion-no-padding ion-text-center"
                                routerLink="/my/products/create/true"
                            >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonIcon
                                            size="large"
                                            icon={bagAddOutline}
                                        ></IonIcon>
                                    </IonCardTitle>
                                    <IonText>Add Products +</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard
                                className="ion-no-margin ion-no-padding ion-text-center"
                                routerLink="/my/catalogue/category/add/false"
                            >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonIcon
                                            size="large"
                                            icon={gridOutline}
                                        ></IonIcon>
                                    </IonCardTitle>
                                    <IonText>Add Category +</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div> <div className="dashborad-grid-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="4">
                            <IonCard
                                className="ion-no-margin ion-no-padding ion-text-center"
                                href="https://manage.intelikart.com"
                                target="_blank"
                            >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonIcon
                                            size="large"
                                            icon={globeOutline}
                                        ></IonIcon>
                                    </IonCardTitle>
                                    <IonText>Web Store Manager</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard
                                className="ion-no-margin ion-no-padding ion-text-center"
                                href="https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp"
                                target="_blank"
                            >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonIcon
                                            size="large"
                                            icon={manOutline}
                                        ></IonIcon>
                                    </IonCardTitle>
                                    <IonText>Driver Application</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard
                                className="ion-no-margin ion-no-padding ion-text-center"
                                routerLink="/my/settings"
                            >
                                <IonCardHeader>
                                    <IonCardTitle>
                                        <IonIcon
                                            size="large"
                                            icon={colorPaletteOutline}
                                        ></IonIcon>
                                    </IonCardTitle>
                                    <IonText>Change Theme</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div> <IonGrid className="ion-text-center">
                <IonRow>
                    <IonCol className="ion-padding">
                        <IonText  color="primary">More Settings</IonText>
                    </IonCol>
                </IonRow>
            </IonGrid> 

			<IonToast
				isOpen={showURLToast}
				onDidDismiss={() => setShowURLToast(false)}
				message="Your Unique URL copied"
				duration={500}
			/>
			<IonToast
				isOpen={showCodeToast}
				onDidDismiss={() => setShowCodeToast(false)}
				message="Your Unique Code copied"
				duration={500}
			/>
			<IonToast
				isOpen={showWebsiteToast}
				onDidDismiss={() => setShowWebsiteToast(false)}
				message="Intelikart Website URL copied"
				duration={500}
			/>
			<IonToast
				isOpen={showAppToast}
				onDidDismiss={() => setShowAppToast(false)}
				message="Intelikart App URL copied"
				duration={500}
			/>
			<IonToast
				isOpen={showLandingToast}
				onDidDismiss={() => setShowLandingToast(false)}
				message="Landing Page URL copied"
				duration={500}
			/>
			<IonLoading
				isOpen={
					homeScreenLoading === true ||
					homeScreenWithDaysLoading === true ||
					landingPagesLoading === true
				}
				message={'Please Wait...'}
			/>*/}
		</IonContent>
	);
};

export default HomeScreen;
