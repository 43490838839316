import {
	IonButton,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React from 'react';
import { addOutline as addOutlineIcon } from 'ionicons/icons';
import Title from '../../components/dashboard/Header';
import { useHistory } from 'react-router';

const Operations: React.FC = () => {
	const history = useHistory();
	return (
		<IonPage>
			<Title name="Operations" />
			<IonContent className="ion-padding" scrollY={false}>
				<IonGrid>
					<IonRow className="ion-justify-content-end">
						<IonButton
							shape="round"
							expand="full"
							onClick={() => history.push('/app/operations/create/true')}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Add Report Item</IonText>
						</IonButton>
					</IonRow>
				</IonGrid>
				<iframe
					title="Finance Report Form"
					className="ion-padding ion-margin"
					src="https://forms.zohopublic.com/virtualoffice268/report/OperationalReport/reportperma/OS0gYxMG7JX2gDDBDQuKf67aX2pCff3cKvOMBZxBlNk"
					style={{ width: '100%', height: '100%' }}
					frameBorder="0"
				></iframe>
			</IonContent>
		</IonPage>
	);
};

export default Operations;
