import {
	IonContent,
	IonHeader,
	IonLoading,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import React from 'react';
import Title from '../../components/dashboard/Header';
import OrderList from '../../components/order/OrderList';
import { useSelector } from 'react-redux';

const AllOrders: React.FC = () => {
	const { ordersLoading, orders, ordersError } = useSelector(
		(state: any) => state.orders
	);
	return (
		<IonPage>
			<Title name="Orders" />
			<IonContent>
				<OrderList orders={orders} />
				<IonLoading isOpen={ordersLoading} message="Please wait..." />
			</IonContent>
		</IonPage>
	);
};

export default AllOrders;
